<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-2">
                                <h4 class="card-title">All Categories</h4>
                            </div>
                            <div class="col-md-10 text-right">
                                <b-button v-b-modal.modal-center variant="success">
                                    <i class="ri-add-line  align-middle mr-2"></i> Add category
                                </b-button>
                                <b-modal
                                        id="modal-center"
                                        centered
                                        title="Add Category"
                                        title-class="font-18"
                                        ref="my-modal"
                                        hide-footer

                                >
                                    <form @submit.prevent="createCategory">
                                        <label >Enter Category</label>
                                        <input
                                                type="text"
                                                v-model="titre"
                                                class="form-control"
                                                placeholder="Enter Category"
                                        />
                                        <label >Enter Description</label>
                                        <textarea
                                                v-model="descrip"
                                                class="form-control"
                                                :maxlength="225"
                                                rows="3"
                                                placeholder="Comment"
                                        ></textarea>
                                        <button
                                                class="btn btn-primary w-md waves-effect waves-light mt-4"
                                                type="submit"
                                                @click="hideModal"
                                        >Save</button>
                                    </form>

                                </b-modal>
                                <b-modal
                                        id="modal-update"
                                        centered
                                        title="Update Category"
                                        title-class="font-18"
                                        ref="my-modal"
                                        hide-footer

                                >
                                    <form class="needs-validation" @submit.prevent="updateCategory">
                                        <label for="validationCustom01" >Enter Category</label>
                                        <input
                                                type="text"
                                                v-model="titre"
                                                class="form-control"
                                                placeholder="Enter Category"
                                                id="validationCustom01"
                                                :class="{ 'is-invalid': submitted && $v.titre.$error }"
                                        />
                                        <div v-if="submitted && $v.titre.$error" class="invalid-feedback">
                                            <span v-if="!$v.titre.required">This value is required.</span>
                                        </div>
                                        <label >Enter Description</label>
                                        <textarea
                                                v-model="descrip"
                                                class="form-control"
                                                :maxlength="225"
                                                rows="3"
                                                placeholder="Comment"
                                        ></textarea>
                                        <label > Type</label>

                                        <button
                                                class="btn btn-primary w-md waves-effect waves-light mt-4"
                                                type="submit"
                                                @click="hideModal"
                                        >Save</button>
                                    </form>

                                </b-modal>
                                <b-modal
                                        id="modal-delete"
                                        centered
                                        title="Confirm Delete"
                                        title-class="font-18"
                                        ref="my-modal"
                                        hide-footer

                                >
                                    <center>  <span  >Are you sure you want to Delete?</span></center>
                                    <div class="text-center">
                                        <button
                                                class="btn btn-danger w-md waves-effect waves-light mt-4"
                                                @click="hideModal"
                                        >No</button>
                                        <button
                                                class="btn btn-primary w-md waves-effect waves-light mt-4 mx-3"
                                                type="submit"
                                                @click="del(selectedCat)"
                                        >Yes</button>
                                    </div>

                                </b-modal>
                            </div>

                        </div>

                        <div class="row mt-4">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show&nbsp;
                                        <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                                    </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input
                                                v-model="filter"
                                                type="search"
                                                placeholder="Search..."
                                                class="form-control form-control-sm ml-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                            <b-table
                                    :items="categoryData"
                                    :fields="fields"
                                    responsive="sm"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :filter="filter"
                                    :filter-included-fields="filterOn"
                                    @filtered="onFiltered"
                            >

                                <template v-slot:cell(actions)="{item}">
                                    <!--                                    <div class="row justify-content-end">-->
                                    <b-button v-b-modal.modal-update v-b-tooltip.hover title="Edit" @click="update(item)" class="actionBut"  variant="success">
                                        <i class="ri-pencil-fill align-middle "></i>
                                    </b-button>
                                    <b-button v-b-tooltip.hover title="Delete" class="actionBut" @click="update(item)" v-b-modal.modal-delete variant="danger">
                                        <i class="ri-delete-bin-2-fill align-middle "></i>
                                    </b-button>

                                    <!--                                    </div>-->
                                </template>
                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-right">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>

</template>

<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import { tableData } from "./dataCountries";
    import firebase from "../../../firebaseInit";
    import {required} from "vuelidate/lib/validators"
    const db = firebase.firestore();
    // import { doc, getDOc } from "firebase/firestore";
    //import { collection, query, where, getDocs } from "firebase/firestore";


    /**
     * Advanced table component
     */
    export default {
        page: {
            title: "View Categories",
            meta: [{ name: "description", content: appConfig.description }]
        },
        components: { Layout, PageHeader },
        data() {
            return {
                categoryData: [],
                tableData: tableData,
                dat:"",
                title: "View Categories",
                items: [
                    {
                        text: "Dashboard",
                        href: "/"
                    },
                    {
                        text: "View Fixed Categories",
                        active: true
                    }
                ],
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortBy: "",
                sortDesc: false,
                fields: [
                    { key: "id", sortable: true },
                    { key: "title", sortable: true },
                    { key: "description", sortable: false },
                    { key: "updated_at", sortable: false },
                    { key: "actions", sortable: false }
                ],
                value:null,
                submitted:false,
                titre:"",
                descrip:"",
                validations:{
                    titre:{required}
                }

            };
        },
        computed: {
            /**
             * Total no. of records
             */
            rows() {
                return this.categoryData.length;
            }
        },
        mounted(){
            // Set the initial number of items
            this.totalRows = this.items.length;
            db.collection("fixed_categories")
                .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {

                        let d=new Date(doc.data().updated_at).toLocaleString()
                        this.categoryData.push({
                            id: doc.id,
                            title: doc.data().title,
                            description: doc.data().description,
                            updated_at:d,
                        });
                        console.log(doc.id, " => ", doc.data());
                    });
                    console.log(this.categoryData)
                    // this.categoryData=JSON.parse(JSON.stringify(this.categoryData))
                    // console.log(this.categoryData)
                    //  return this.categoryData

                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                });
        },
        methods: {
            createCategory() {
                // this.submitted = true;
                // // stop here if form is invalid
                // this.$v.$touch();

                db.collection("fixed_categories")
                    .add({
                        title: this.titre,
                        description: this.descrip,
                        created_at:new Date().getTime(),
                        updated_at:new Date().getTime(),
                    })
                    .then(() => {
                        this.categoryData.push()
                        console.log("Category created successfully !");
                    })
                    .catch((error) => {

                        console.error("Error: ", error);
                    }).finally(()=>{

                });
            },
            del(e){
                //const  index = this.categoryData.indexOf(e)
                console.log(e)
                db.collection("fixed_categories")
                    .doc(e.id)
                    .delete()
                    .then(() => {
                        //this.categoryData.splice(index,1)
                        this.categoryData = this.categoryData.filter(ev=>{
                            return ev.id != e.id
                        })
                        window.makeToast('Successful','Category Deleted successful','success')
                        console.log("Category successfully deleted!");

                    })
                    .catch((error) => {
                        console.error("Error removing document: ", error);
                        window.makeToast('Error','Error deleting category','danger')
                    });

            },
            update(e) {
                console.log(e);
                console.log(e);
                console.log(e.id);
                //start loading
                db.collection('fixed_categories').doc(e.id).get()
                    .then((doc)=>{
                        //doc.data();
                        console.log(doc.data())
                        this.selectedCat={
                            ...doc.data(),
                        };
                        console.log(this.selectedCat)
                        return this.selectedCat
                    }).catch(e=>{
                    console.error(e);
                    //toast
                }).finally(()=>{
                    //stop loading
                })
            },
            updateCategory() {
                db.collection("fixed_categories")
                    .doc(this.selectedCat.id)
                    .update({
                        ...this.selectedCat
                    })
                    .then(() => {
                        console.log("Category successfully updated!");
                    })
                    .catch((error) => {
                        console.error("Error updating document: ", error);
                    });
            },

            /**
             * Search the table data with search input
             */
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },
            hideModal() {
                this.$refs['my-modal'].hide()
            },
        }
    };
</script>

<style scoped>
    .actionBut{
        margin-left:15px;
    }
</style>